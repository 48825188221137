
*{
    margin: 0;
    padding: 0;
 
  }
  .image-container {
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    background-color:#f4a618; /* Bright green color */
    border-radius: 20px;
  }
  
  .curved-frame {
    width: 100%;
    height: 0;
    padding-bottom: 75%; /* Adjust this value to change the aspect ratio */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .curved-frame::before {
    content: '';
    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;
    background-color:#f4a618; /* Same as container background */
    border-radius: 50%/10%;
  }
  
  .curved-frame img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }  

  .home-bg{
    background-image: url('../assets/waves.svg');
   
  }
  .banner{
    background-image: url('https://cdn.pixabay.com/photo/2021/08/29/15/44/woman-6583628_1280.jpg');
    background-size: cover;
    overflow: hidden;
    height:100vh;
    background-position: center;
    position: relative;
  }
  .banner .waves{
position: absolute;
bottom: 0px;
width: 100%;
left: 0px;
  }
  .banner .banner-text{
    position: absolute;
    top:20%;
    left:20%;
  }
  .custom-toggler{
        background-color:#f4a618;
        border-color:#f4a618;
  }

/* AnimatedText.css */
.animated-text {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s ease-in-out;
}

.sticky {
    opacity: 1;
    transform: translateY(0);
}
.footer-container {
    position: relative; /* Ensure that child elements can be positioned correctly */
    overflow: hidden; /* Prevents overflow from the pseudo-element */
    width: 100%;
    height: 40vh;
}

.footer-container::before {
    content: ""; /* Necessary for the pseudo-element */
    position: absolute; /* Position it absolutely */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/wave.svg');
    opacity: 0.3; /* Set the desired opacity for the background image */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 0; /* Send the pseudo-element to the back */
}

.footer-content {
    position: relative; /* Ensure footer content is above the background */
    z-index: 1; /* Ensure the text is above the pseudo-element */
}
.carousel-image {
  width: 100%;
  max-height: 500px; /* Controls the height of carousel images */
  object-fit: cover; /* Ensures image fills the container without stretching */
}

@media (max-width: 768px) {
  .carousel-image {
    max-height: 300px; /* Reduces height on smaller screens */
  }
}
/* AboutPage.css */
.about-page {
  background-color: #000;
  color: #f4a618;
  min-height: 100vh;
  padding: 5rem 0;
}

.about-title {
  font-size: 3rem;
  font-weight: bold;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.about-subtitle, .about-text, .about-feature {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.about-feature h4 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f4a618;
}

.reveal {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.text-gold {
  color: #f4a618;
}

@media (max-width: 768px) {
  .about-title {
    font-size: 2.5rem;
  }

  .about-feature h4 {
    font-size: 1.3rem;
  }
}
.contact-form-container {
  background-color: #000;
  color: #f4a618;
}

.contact-form {
  background-color: #1e1e1e;
  color: #f4a618;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  transform: translateY(50px);
  opacity: 0;
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.contact-form.reveal {
  transform: translateY(0);
  opacity: 1;
}

.contact-form h2 {
  color: #f4a618;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.contact-form label {
  color: #f4a618;
}

.contact-form .form-control {
  background-color: #333;
  color: #f4a618;
  border: 1px solid #f4a618;
}

.contact-form .form-control:focus {
  box-shadow: none;
  border-color: #ffd700;
}

.contact-form .btn-warning {
  background-color: #f4a618;
  border: none;
}

.contact-form .btn-warning:hover {
  background-color: #ffd700;
  color: #333;
}
/* about page */
.about-page{
  color:#f4a618;
}
/*navbar*/
.hover:hover{
  background: #d98a14; /* Darker gold shade on hover */
  box-shadow: 0px 0px 15px rgba(255, 223, 0, 0.5) !important; /* Gold shadow effect */
  transform: translateY(-2px); /* Optional: makes the button appear to lift */
}
#enroll{
  color:white;
  animation-name: enroll !important;
animation-iteration-count: infinite !important;
animation-duration: 2s;
}
@keyframes enroll {
  0%{
    background-color: #ffd700;
  }
  40%{
    background-color: #333;
  }
  70%{
    background-color: #f4a618;
  }
  80%{
    background-color: #ffd700;
  }
  90%{
    background-color: #333;
  }
  100%{
    background-color: #f4a618;
  }
}
.text-gold{
  color:#d98a14 !important;
}
/* Type writing text for welcome */
.type-writer-container {
  display: flex;
  color:#d98a14 !important;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width of the viewport */
  background-color: #000; /* Optional background to emphasize the text */
  margin: 0;
  overflow: hidden; /* Prevents overflow */
}

.type-writer-text {
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 40px;
  text-align: center;
  border-right: 4px solid #d98a14;
  animation: type 5s steps(30) infinite, blink 0.6s ease-out infinite; /* Infinite loop for typing */
}

@keyframes blink {
  0% {
    border-right: 4px solid #d98a14;
  }
  100% {
    border-right: 4px solid #000;
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  50% {
    width: 100%; /* The text is fully typed */
  }
  100% {
    width: 0; /* Reset the width back to 0 for the next cycle */
  }
}
/*navbar*/
.navbar-toggler-icon {
  background-color: #f4a618; 
  border-radius: 10%;
  /* Set your desired color here */
}